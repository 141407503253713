
.project-container {
    display: flex;
    flex-direction: column;
    padding: 20px 70px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: #fff;
    .project-table {
        ::v-deep table {
            ::v-deep td:last-child {
                .cell {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .like {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
    .difficulty {
        color: #FFD83E;
    }
    .pages-center {
        background-color: #fff;
        padding: 20px 0;
    }
}
.back {
    text-align: right;
}
